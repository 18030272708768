<template>
    <div class="mainBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-wrap">
                <label>场景：</label>
                <el-input placeholder="订单编号/姓名/手机号" v-model="select.search" size="small" style="width:360px; "@key.enter="curr=1;getOrderList()"  @change="curr=1;getOrderList()">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
				<!-- <el-select v-model="select.cate_id" placeholder="品类会员" clearable style="width:110px" @change="curr=1;getOrderList()">
				    <el-option v-for="item in selectData['品类会员']" :label="item.name" :value="item.id" :key="item.id"></el-option>
				</el-select> -->
				<el-select v-model="select.status" placeholder="订单状态" clearable style="width:110px" @change="curr=1;getOrderList()">
				    <el-option v-for="item in selectData['订单状态']" :label="item.name" :value="item.id" :key="item.id"></el-option>
				</el-select>
                <el-date-picker v-model="select.renew_time" style="width:200px;margin-right:auto" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getOrderList()">
                </el-date-picker>
                <el-button type="success" block size="mini">订单总金额：{{totalMoney}}元</el-button>
                <el-button type="primary" block size="mini" @click="exportFun()">导出</el-button>
            </div>
        </div>
        <div class="table" style="height:calc( 100% - 175px );">
            <el-table :data="list" ref="barparent" stripe border height="calc( 100% - 41px )">
				<el-table-column prop="order_sn" align="left" label="订单编号" show-overflow-tooltip>
				</el-table-column>
				<!-- <el-table-column prop="cate_id" align="left" label="品类会员分类" show-overflow-tooltip>
					<template slot-scope="scope">
						<span v-if="scope.row.cate_id == 1">
							单品类
						</span>
						<span  v-if="scope.row.cate_id == 2">
							多品类
						</span>
					</template>
				</el-table-column> -->
				<el-table-column prop="name" align="left" label="商品" show-overflow-tooltip>
				</el-table-column>
                <el-table-column prop="price" align="left" label="实付" show-overflow-tooltip>
                </el-table-column>
				<el-table-column prop="end_time" align="left" width="210" label="到期日期" show-overflow-tooltip>
					<template slot-scope="scope">
						<span >{{scope.row.end_time}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="renew_time" align="left" width="210" label="续费日期" show-overflow-tooltip>
					<template slot-scope="scope">
						<span >{{scope.row.renew_time}}</span>
					</template>
				</el-table-column>	
				<el-table-column prop="years" align="left" label="天数" show-overflow-tooltip>
				</el-table-column>
                <el-table-column prop="address" align="left" width="210" label="客户信息" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="flex regoods">
                            <label>姓名：</label>
                            <div>{{scope.row.user && scope.row.user.realname ? scope.row.user.realname : '暂无'}}</div>
                        </div>
						<div class="flex regoods">
						    <label>汽修厂：</label>
						    <div>{{scope.row.user && scope.row.user.garage_name ? scope.row.user.garage_name : '暂无'}}</div>
						</div>
                        <div class="flex regoods">
                            <label>电话：</label>
                            <div>{{scope.row.user && scope.row.user.mobile  ? scope.row.user.mobile : '暂无'}}</div>
                        </div>
                        <div class="flex regoods">
                            <label>地址：</label>
							<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.user ? scope.row.user.province+scope.row.user.city+scope.row.user.country : '暂无'}}</div>
                        </div>
                    </template>
                </el-table-column>
				<el-table-column prop="status" align="left" label="订单状态" show-overflow-tooltip>
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1" style="color:green;">
							待支付
						</span>
						<span v-if="scope.row.status == 2" style="color:red;">
							已支付
						</span>
						<span v-if="scope.row.status == 3" style="color:green;">
							已失效
						</span>
						<span v-if="scope.row.status == 4" style="color:red;">
							已过账
						</span>
					</template>
				</el-table-column>
                <el-table-column  fixed="right"  label="操作" align="center" width="200">
                    <template slot-scope="scope">
                		<el-button  @click="printDiao(scope.row.id)"  size="mini" type="primary"  v-if="scope.row.status ==2 ">打印</el-button>
                    </template>
                </el-table-column> 
            </el-table>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { orderRenewListApi} from '@/api/order.js';
export default {
    data() {
        return {
            selectData: {
                '品类会员': [{
                    id: 1,
                    name: '单品类'
                }, {
                    id: 2,
                    name: '多品类'
                }],
				'订单状态': [{
				    id: 1,
				    name: '待支付'
				}, {
				    id: 2,
				    name: '已支付'
				}, {
				    id: 4,
				    name: '已过账'
				}]
            },
            select: {
                search: '',
                cate_id: '',
                renew_time: []
            },

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            totalMoney: 0, //订单总金额

            tableWidth: 0,
            list: []
        }
    },

    mounted() {
        if(this.$route.query.admin_id){
            this.select['admin_id'] = this.$route.query.admin_id
        }   
        this.tableWidth = this.$refs.barparent.bodyWidth;
        //订单列表
        this.getOrderList();
    },
    methods: {
        //订单列表
        getOrderList: function () {
            orderRenewListApi({
                curr: this.curr,
                row: this.row,
                ...this.select
            }).then(response => {
                this.totalMoney = response.amount;
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },
          
        //导出
        exportFun: function () {
            this.$confirm(`确定导出订单吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let url = `/exportExcel/order?param={"search":"${this.select.search}","cate_id":"${this.select.cate_id}","create_time":${JSON.stringify(this.select.create_time ? this.select.create_time : [])}}`;
                location.href = this.domain + url;
                loading.close();
            }).catch(() => {
                this.$message('已取消当前操作')
            })
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getOrderList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getOrderList();
        },
		// 新页面打印
		printDiao: function(id) {
			let url = this.$router.resolve({
				path:"./printRenew",
				query:{
					id:id
				}
			})
			window.open(url.href,'_blank')//注意，需要加hr
			
			  
		},
		
    },
}
</script>
<style scoped>
    .el-table--enable-row-transition >>> .el-table__body td {
    }
    :v-deep .el-table tr {
        position: relative;
        width: 100%;
    }
    .item {
        background: #f2f3ff;
        padding: 10% 0;
        position: absolute;
        top: 0;
        left: 0px !important;
        z-index: 4;
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;
    }
    .mesSty .pic {
        width: 80px;
        height: 80px;
        margin: 0 10px;
        object-fit: cover;
        border-radius: 3px;
        display: block;
    }
    .mesSty {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebeef5;
    }
    
    .mesSty:last-child {
        border: none;
    }
</style>